




















import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {
    LabelStatus: () => import("@/components/Label/LabelStatus.vue")
  }
})
export default class FilterStatus extends Vue {
  @Prop({ default: "admin" }) readonly role!: string;
  private statuses = [
    "pending",
    "returned",
    "approved",
    "rejected",
    "cancelled",
    "paid"
  ];
  get model() {
    return this.$store.state.FiltersModule.filters.statuses;
  }
  set model(payload: string) {
    this.$store.commit("FiltersModule/FILTER_STATUS", payload);
    this.$emit("filter");
  }
  get items() {
    return this.statuses.map(status => {
      return { text: this.$t(`status.${this.role}.${status}`), value: status };
    });
  }
  get showStatus() {
    return this.model?.length && this.model?.length === 1;
  }
  showText(index: number) {
    return this.model.length && this.model.length > 1 && index === 0;
  }
}
